import UploadImages from "../../views/Cms/Images";

const UploadImagesRoutes = [
  {
    path: "/uploadImages",
    exact: true,
    component: UploadImages,
  },
];

export default UploadImagesRoutes;
