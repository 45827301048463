import { Grid } from "react-feather";

export default [
  {
    id: "bookings",
    title: "Bookings",
    icon: <Grid />,
    navLink: "/bookings",
  },
];
