import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";

const AlertConfig = () => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [media, setMedia] = useState([
    {
      id: uuidv4(),
      description: "",
    },
  ]);
  const stored = state?.tableData;

  useEffect(() => {
    if (stored?.flybigAlerts && stored?.flybigAlerts?.length > 0) {
      setMedia(
        stored?.flybigAlerts.map((item) => {
          return {
            id: uuidv4(),
            description: item?.description,
          };
        })
      );
    }
  }, [stored?.flybigAlerts]);

  useEffect(() => {
    let updated = true;
    if (
      media &&
      stored?.flybigAlerts &&
      media.length === stored?.flybigAlerts.length
    ) {
      updated = false;
      stored?.flybigAlerts.forEach((item, index) => {
        if (!(item.description === media[index].description)) {
          updated = true;
          return;
        }
      });
    }
    setSaveDisabled(!updated);
  }, [media]);

  const handleChange = (val, id, key) =>
    setMedia(
      media.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleAddMedia = () =>
    setMedia([...media, { id: uuidv4(), description: "" }]);

  const handleRemoveMedia = (id) => {
    let newMediaData = media.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    setMedia(newMediaData);
  };

  const handleSubmitHandler = async () => {
    let isValid = true;
    let mediaClone = media;

    mediaClone?.forEach((item) => {
      isValid && (item?.description ? (isValid = true) : (isValid = false));
    });

    if (isValid) {
      mediaClone.forEach((item) => {
        delete item.id;
      });
      dispatch(
        tableDataHandler({
          ...stored,
          flybigAlerts: mediaClone,
        })
      );
      const res = [];
      mediaClone.forEach((item) => {
        res.push({
          title: item.description,
        });
      });
      const updated = await API.updateUiSectionById(stored?.flybigAlertsId, {
        details: res,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {media.length &&
        media.map(({ id, description, link, date }) => (
          <div key={id}>
            <Row className="mt-2">
              <Col sm="12" md="11">
                <FormGroup>
                  <FormGroup className="w-100">
                    <Label className="form-label" for="description">
                      Description
                    </Label>

                    <Input
                      type="text"
                      value={description}
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={(e) =>
                        handleChange(e.target.value, id, e.target.name)
                      }
                    />
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col sm="12" md="1">
                {media.length > 1 ? (
                  <>
                    <Button.Ripple
                      className="btn-icon rounded-circle mb-1 mr-1"
                      color="danger"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveMedia(id)}
                    >
                      <Trash size={14} />
                    </Button.Ripple>
                  </>
                ) : null}
              </Col>
            </Row>
          </div>
        ))}

      <SaveDiscardBtnGroup addAction={handleAddMedia} disabled={saveDisabled} />
    </form>
  );
};

export default AlertConfig;
