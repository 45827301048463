import { DashboardActionTypes } from "./routes.actionTypes";

const { TABLE_DATA } = DashboardActionTypes;

export const tableDataHandler = (data) => {
  return {
    type: TABLE_DATA,
    payload: data,
  };
};
