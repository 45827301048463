import Axios from "axios";
import { store } from "../../redux/store";
import { userLogout } from "../../redux/user/user.action";

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const token = state?.user?.details?.token;

    config.headers.token = token;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error?.response?.status);

    if (error?.response?.status === 401) {
      store.dispatch(userLogout());
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
