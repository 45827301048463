import { Repeat, Map } from "react-feather";

export default [
  {
    id: "routes",
    title: "Configure Routes",
    icon: <Repeat />,
    badge: "light-warning",
    children: [
      {
        id: "routesPopular",
        title: "Popular Routes",
        icon: <Map size={12} />,
        navLink: "/routes/popular",
      },
      {
        id: "routesUDAAN",
        title: "UDAAN Routes",
        icon: <Map size={12} />,
        navLink: "/routes/udaan",
      },
    ],
  },
];
