import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import { Button } from "reactstrap";
import API from "@api";
import "./index.scss";
import toaster from "../Toaster";

const PdfSection = ({ pdfList, getData, setPdfList, isPdfLink = false }) => {

  const uppy = new Uppy({
    meta: { type: "pdf" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [".pdf"],
      maxFileSize: 30 * 1024 * 1024,
    },
  });

  uppy.on("complete", async (result) => {
    const id = result.successful[0].source;
    const formData = new FormData();
    const file = pdfList.find((item) => item.id === id);
    formData.append("name", file?.name);
    formData.append("pdf", result.successful[0].data, file.title);
    const { data } = await API.uploadPdfFile(formData);
    if (data.success) {
      toaster("success","Pdf file uploaded successfully");
    }
    getData();
  });

  return (
    <div className='imageSection__container'>
      <div>
        <h3 className='imageSection__header mt-2'>Upload pdfs</h3>
        <h6>Drag and drop or upload new file</h6>
        <div className='imageSection__imagesContainer'>
          {pdfList.map((item, index) => {
            return (
              <div key={index} className='imagesection__imageBox'>
                <div className='imageSection__addImage ml-2'>
                  <h5>{item.title}</h5>
                  <DragDrop
                    id={item.id}
                    className={item.id}
                    width='12em'
                    height='12em'
                    locale={{
                      strings: {
                        dropHereOr: "Max 30 MB",
                      },
                    }}
                    uppy={uppy}
                  />
                  {console.log(item)}
                  {item.link && item.link.length > 0 ?
                  <Button.Ripple
                  color="light"
                  className='view_btn'
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(item.link, '_blank');
                  }}
                >
                  View
                </Button.Ripple> : null}
                </div>
              </div>

            );
          })}
        </div>
        <hr />
      </div>
    </div>
  );
};

export default PdfSection;
