import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import PdfSection from "@components/common/PdfSection";
import { useSelector, useDispatch } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Pdf = ({getData}) => {
  const state = useSelector((state) => state?.cms);
  const [fileList, setFileList] = useState([]);

  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.pdfFiles && stored?.pdfFiles.length > 0) {
      setFileList(
        stored?.pdfFiles.map((item) => ({ ...item, id: item._id }))
      );
    }
  }, [stored]);

  return (
    <div className='mt-2'>
      <form >
        <Row>
          <PdfSection
            pdfList={fileList}
            setPdfList={setFileList}
            getData={getData}
            landingPage
          />
        </Row>
      </form>
    </div>
  );
};

export default Pdf;
