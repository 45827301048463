import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import API from "@api";
import "./index.scss";
import toaster from "../common/Toaster";
import SaveDiscardBtnGroup from "../common/save-discard-btn-group";
import { v4 as uuidv4 } from "uuid";
import { tableDataHandler } from "@store/Cms/cms.action";
import { Plus } from "react-feather";
import { CKEditor } from "ckeditor4-react";
import removeButtons from "../../../configs/ckeditor";

const FaqSection = ({ dataList, getData }) => {
  const stored = useSelector((state) => state?.cms?.tableData);
  const dispatch = useDispatch();

  const [selectedCatagoryIndex, setSelecedCatagoryIndex] = useState(-1);
  const [renderer, setRenderer] = useState(false);
  const [faq, setFaq] = useState([]);
  useEffect(() => {
    if (stored?.faq && stored?.faq?.length > 0) {
      setFaq(
        stored?.faq.map((item) => {
          let extraDetails = {
            questionAndAnswers: [],
          };
          if (item.extraDetails && item.extraDetails.questionAndAnswers) {
            item.extraDetails.questionAndAnswers.forEach(
              (questionAndAnswer) => {
                extraDetails.questionAndAnswers.push({
                  ...questionAndAnswer,
                  id: uuidv4(),
                });
              }
            );
          }
          return {
            id: item._id || uuidv4(),
            title: item?.title,
            extraDetails,
          };
        })
      );
      setSelecedCatagoryIndex(0);
    }
  }, [stored]);

  const handleChange = (val, id, key) => {
    if (key === "title") {
      faq.forEach((item, index) => {
        if (item.title === val) {
          setSelecedCatagoryIndex(index);
        }
      });
      return;
    }
    const tempFaq = faq;
    if (
      tempFaq &&
      tempFaq[selectedCatagoryIndex] &&
      tempFaq[selectedCatagoryIndex]?.extraDetails &&
      tempFaq[selectedCatagoryIndex]?.extraDetails?.questionAndAnswers
    ) {
      tempFaq[selectedCatagoryIndex]?.extraDetails?.questionAndAnswers.forEach(
        (questionAndAnswer, index) => {
          if (questionAndAnswer.id === id) {
            tempFaq[selectedCatagoryIndex].extraDetails.questionAndAnswers[
              index
            ][key] = val;
          }
        }
      );
    }
    setFaq(tempFaq);
    setRenderer(!renderer);
  };

  const handleAddMedia = () => {
    const newFaq = [...faq];
    newFaq[selectedCatagoryIndex].extraDetails.questionAndAnswers = [
      ...newFaq[selectedCatagoryIndex].extraDetails.questionAndAnswers,
      {
        id: uuidv4(),
        question: "",
        answer: "",
      },
    ];
    setFaq(newFaq);
    setRenderer(!renderer);
  };

  const handleRemoveMedia = (id) => {
    const newData = [...faq];
    newData[selectedCatagoryIndex].extraDetails.questionAndAnswers = faq[
      selectedCatagoryIndex
    ].extraDetails.questionAndAnswers.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    setFaq(newData);
    setRenderer(!renderer);
  };

  const handleSubmitHandler = async (e) => {
    e.preventDefault();

    let isValid = true;
    let faqClone = faq;

    faqClone?.forEach((item) => {
      isValid && (item?.title ? (isValid = true) : (isValid = false));
    });

    if (isValid) {
      faqClone.forEach((item) => {
        delete item.id;
        if (item.extraDetails && item.extraDetails.questionAndAnswers) {
          item.extraDetails.questionAndAnswers.forEach((questionAndAnswer) => {
            delete questionAndAnswer.id;
          });
        }
      });
      dispatch(
        tableDataHandler({
          ...stored,
          faq: faqClone,
        })
      );
      toaster("success", "Data saved successfully");
      const updated = await API.updateUiSectionById(stored?.faqId, {
        details: faqClone,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
      getData();
      window.location.reload();
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <div className="imageSection__container">
      <form>
        <FormGroup className="w-100">
          <Label className="form-label" for="title">
            Catagory
          </Label>
          <select
            id="title"
            name="title"
            placeholder="Catagory"
            onChange={(e) => handleChange(e.target.value, "", "title")}
            value={
              selectedCatagoryIndex && selectedCatagoryIndex !== -1
                ? faq[selectedCatagoryIndex].title
                : ""
            }
            className="form-control"
          >
            {faq.map((item) => {
              return (
                <option key={item.title} value={item.title}>
                  {item.title}
                </option>
              );
            })}
          </select>
        </FormGroup>
      </form>
      <form
        className="mt-2"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitHandler(e);
        }}
      >
        <Row className="mt-2">
          <Col sm="12">
            <FormGroup>
              {(renderer || !renderer) &&
              selectedCatagoryIndex !== -1 &&
              faq[selectedCatagoryIndex].extraDetails ? (
                <div>
                  <h3>Questions and Answers</h3>
                  {faq[
                    selectedCatagoryIndex
                  ].extraDetails?.questionAndAnswers?.map(
                    ({ id, question, answer }) => {
                      return (
                        <div key={id} className="imagesection__imageBox">
                          <div className="imageSection__addImage ml-2">
                            <FormGroup className="w-100">
                              <Label className="form-label" for="question">
                                Question
                              </Label>
                              <Input
                                type="text"
                                value={question}
                                id={id}
                                name="question"
                                placeholder="Question"
                                onChange={(e) =>
                                  handleChange(
                                    e.target.value,
                                    id,
                                    e.target.name
                                  )
                                }
                              />
                            </FormGroup>
                            <FormGroup className="w-100">
                              <Label className="form-label" for="answer">
                                Answer
                              </Label>
                              <CKEditor
                                initData={answer}
                                name={id}
                                onChange={(event) => {
                                  handleChange(
                                    event.editor.getData(),
                                    id,
                                    "answer"
                                  );
                                }}
                                config={{
                                  removeButtons,
                                }}
                                id={id}
                              />
                            </FormGroup>
                            {faq[selectedCatagoryIndex].extraDetails
                              ?.questionAndAnswers?.length > 1 ? (
                              <>
                                {
                                  <Button.Ripple
                                    className="btn-icon mb-1 mr-1"
                                    color="danger"
                                    type="button"
                                    size="md"
                                    onClick={() => handleRemoveMedia(id)}
                                  >
                                    {" "}
                                    Remove
                                  </Button.Ripple>
                                }
                              </>
                            ) : null}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              ) : (
                ""
              )}
              <Button.Ripple
                type="button"
                className="btn-icon rounded mr-1"
                color="light"
                size="md"
                onClick={handleAddMedia}
              >
                <Plus size={14} className="mr-1" />
                Add
              </Button.Ripple>
            </FormGroup>
          </Col>
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default FaqSection;
