import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Udaan from "./udaan";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";


const HomeContentTabs = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await API.getRoutes({type: 'UDAAN'});
    data && dispatch(tableDataHandler({udaan:data.data}));
  };

  useEffect(() => getData(), []);

  return (
    <div>
        <Row>
          <Col>
            <Card>
              <CardHeader className='border-bottom'>
                <CardTitle>
                  Routes - UDAAN
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Udaan getData={getData}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
  );
};

export default HomeContentTabs;

