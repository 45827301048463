import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getHomeContent = () =>
  axios.get("/home-content").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const updateHomeContent = (data) =>
  axios.patch("/home-content", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const uploadImage = (data) =>
  axios.post("/home-content/upload", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

export default {
  getHomeContent,
  updateHomeContent,
  uploadImage,
};
