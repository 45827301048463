import { Layout, HelpCircle } from "react-feather";

export default [
  {
    id: "faq",
    title: "FAQ",
    icon: <Layout />,
    badge: "light-warning",
    children: [
      {
        id: "faqCatagories",
        title: "FAQ Catagories",
        icon: <HelpCircle size={12} />,
        navLink: "/cms/faqCatagories",
      },
      {
        id: "faq",
        title: "FAQs",
        icon: <HelpCircle size={12} />,
        navLink: "/cms/faqs",
      },
    ],
  },
];
