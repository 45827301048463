import Popular from "../../views/Routes/popular";
import UDAAN from "../../views/Routes/uddan";

const Routes = [
  {
    path: "/routes/popular",
    exact: true,
    component: Popular,
  },
  {
    path: "/routes/udaan",
    exact: true,
    component: UDAAN,
  }
];

export default Routes;
