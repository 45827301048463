import { convertToHTML, convertFromHTML } from "draft-convert";
import { EditorState } from "draft-js";

export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const colourOptions = [
  { value: "ocean", label: "Ocean" },
  { value: "blue", label: "Blue" },
  { value: "purple", label: "Purple" },
  { value: "red", label: "Red" },
  { value: "orange", label: "Orange" },
];

export const emailValidationHandler = (value) => {
  if (value) {
    let validationCondition =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = validationCondition.test(String(value).toLowerCase());
    return isValid;
  }
  return false;
};

export const dateFormatHandler = (date) => {
  let myDate = new Date(date);

  if (date.includes("/")) {
    return date;
  }

  let currentDate = myDate.getDate();
  let month = myDate.getMonth() + 1;
  let year = myDate.getFullYear();

  return `${currentDate}/${month}/${year}`;
};

export const fileUpload = (e) => {
  if (!e.target.files[0]) return;
  return e.target.files[0];
};

export const inputDataConvertor = (editorState) =>
  convertToHTML(editorState.getCurrentContent());

export const htmlToDraftConvert = (value) => {
  value = value
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&quot;/g, '"');

  return EditorState.createWithContent(convertFromHTML(value));
};
