import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getRoutes = async (params) => {
  const data = await axios.get("/route", { params }).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });
  return data;
}

const createRoute = async (data) => axios.post("/route", data).catch((error) => {
  toaster("error", error?.response?.data?.message);
});

const updateRoutes = async (data) =>
  axios.patch("/route", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const uploadPopularImage = (data) =>
  axios.post("/route/upload", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const deleteRoute = async (id) => 
  axios.delete(`/route/${id}`).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const getOriginAndDestination = async () => axios.get("/route/allRoutes").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

export default { getRoutes, updateRoutes, uploadPopularImage, deleteRoute, getOriginAndDestination, createRoute };
