import { useSkin } from "@hooks/useSkin";
import ForgotPasswordStepOne from "@components/Auth/ForgotPassword/ForgotPasswordStepOne";
import ForgotPasswordStepTwo from "@components/Auth/ForgotPassword/ForgotPasswordStepTwo";
import ForgotPasswordStepThree from "@components/Auth/ForgotPassword/ForgotPasswordStepThree";
import AuthWrapper from "@components/Auth/common/AuthWrapper";
import { useState } from "react";

const ForgotPassword = () => {
  const [skin, setSkin] = useSkin();
  const [activeTab, setActiveTab] = useState(1);

  const illustration =
      skin === "dark"
        ? "forgot-password-v2-dark.svg"
        : "forgot-password-v2.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default;

  return (
    <AuthWrapper source={source}>
      {activeTab === 1 && <ForgotPasswordStepOne />}
      {activeTab === 2 && <ForgotPasswordStepTwo />}
      {activeTab === 3 && <ForgotPasswordStepThree />}
    </AuthWrapper>
  );
};

export default ForgotPassword;
