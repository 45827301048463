import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Popular from "./popular";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/routes/routes.action";


const PopularRoutes = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await API.getRoutes({type: 'popular'});
    const getOriginAndDestination = await API.getOriginAndDestination();
    data && dispatch(tableDataHandler({popular:data.data, allRoutes: getOriginAndDestination.data.data}));
  };

  useEffect(() => getData(), []);

  return (
    <div>
        <Row>
          <Col>
            <Card>
              <CardHeader className='border-bottom'>
                <CardTitle>
                  Routes - Popular
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Popular getData={getData}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
  );
};

export default PopularRoutes;

