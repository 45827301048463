import AuthRoutes from "./Auth";
import BookingRoutes from "./Booking";
import UserRoutes from "./User";
import CmsRoutes from "./Cms";
import RoutesRoutes from "./Routes";
import UploadImagesRoutes from "./UploadImages";

const TemplateTitle = "Flybig Admin";

const DefaultRoute = "/bookings";

const Routes = [
  ...AuthRoutes,
  ...BookingRoutes,
  ...UserRoutes,
  ...CmsRoutes,
  ...RoutesRoutes,
  ...UploadImagesRoutes,
];

export { DefaultRoute, TemplateTitle, Routes };
