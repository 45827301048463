import Router from "./router/Router";
import AppLoader from "@components/common/AppLoader";
import { useSelector } from "react-redux";

const App = () => {
  const state = useSelector((state) => state.loader);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      {state.loading && <AppLoader />}

      <Router />
    </div>
  );
};

export default App;
