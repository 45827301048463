import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import BannerImage from "./BannerImage";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";

const HomeContentTabs = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const sectionData = await API.getAllUiSection();
    const data = sectionData?.data?.data;
    const bannerImages = data
      .filter((item) => item.bannerImage && item.bannerImage.length > 0)
      .map((item) => {
        return {
          bannerImage: item.bannerImage,
          title: item.title,
          _id: item._id,
        };
      });
    console.log(bannerImages);
    data && bannerImages && dispatch(tableDataHandler({ bannerImages }));
  };

  useEffect(() => getData(), []);

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle>Banner Images</CardTitle>
            </CardHeader>
            <CardBody>
              <BannerImage getData={getData} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomeContentTabs;
