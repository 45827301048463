// ** React Imports

// ** Vertical Menu Components
import VerticalNavMenuLink from './VerticalNavMenuLink';
import VerticalNavMenuGroup from './VerticalNavMenuGroup';
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader';

// ** Ability Context

// ** Utils
import {
  resolveVerticalNavMenuItemComponent as resolveNavItemComponent,
  CanViewMenuGroup,
  CanViewMenuItem,
} from '@layouts/utils';

const VerticalMenuNavItems = (props) => {
  // ** Components Object
  const Components = {
    VerticalNavMenuSectionHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink,
  };

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)];
    if (item.children) {
      return (
        CanViewMenuGroup(item) && (
          <TagName item={item} index={index} key={item.id} {...props} />
        )
      );
    }
    return (
      CanViewMenuItem(item) && (
        <TagName key={item.id || item.header} item={item} {...props} />
      )
    );
  });

  return RenderNavItems;
};

export default VerticalMenuNavItems;
