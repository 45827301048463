import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getAllPdfFile = () =>
  axios.get("/pdfFile").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const getPdfFileById = () =>
  axios.get("/pdfFile/id").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const createPdfFile = (data) =>
  axios.post("/pdfFile", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const updatePdfFile = (data) =>
  axios.patch("/pdfFile", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const uploadPdfFile = (data) =>
  axios.post("/pdfFile/upload", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

export default { getAllPdfFile, getPdfFileById, createPdfFile, updatePdfFile, uploadPdfFile };
