import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SpecialOfferSection from "@components/SpecialOfferSection";
import { useSelector, useDispatch } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import UiSection from "@components/common/newUISection";

const About = ({ getData, sectionId }) => {
  const state = useSelector((state) => state?.cms);
  const [about, setAbout] = useState([]);
  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.about && stored?.about.length > 0) {
      setAbout(stored?.about.map((item) => ({ ...item, id: item._id })));
    }
  }, [stored]);

  return (
    <div className='mt-2'>
      <Row>
        <UiSection
          dataList={about}
          getData={getData}
          sectionName='about'
          sectionId={sectionId}
        />
      </Row>
    </div>
  );
};

export default About;
