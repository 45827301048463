import { Upload } from "react-feather";

export default [
  {
    id: "images",
    title: "Upload Images",
    icon: <Upload />,
    navLink: "/uploadImages",
  },
];
