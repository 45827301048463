import Booking from "../../views/Booking";

const BookingRoutes = [
  {
    path: "/bookings",
    exact: true,
    component: Booking,
  },
];

export default BookingRoutes;
