import { useEffect } from "react";
import { useDispatch } from "react-redux";
import loginApi from "./Login";
import bookingApi from "./Booking";
import userApi from "./User";
import blogsApi from "./Blogs";
import mediaApi from "./Media";
import homeContentApi from "./HomeContent";
import PdfFiles from "./PdfFiles";
import UiSection from "./UiSection";
import Routes from "./Routes";
import { startLoading, stopLoading } from "@store/loader/loader.action";

const useLoadingHandler = (loading) => {
  const dispatch = useDispatch();

  useEffect(() => {
    loading ? dispatch(startLoading()) : dispatch(stopLoading());
  }, [loading]);
};

export default {
  ...loginApi,
  ...bookingApi,
  ...userApi,
  ...blogsApi,
  ...mediaApi,
  ...homeContentApi,
  ...PdfFiles,
  ...UiSection,
  ...Routes,
};
