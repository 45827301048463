import { useState, useRef, useEffect } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import InputPasswordToggle from "@components/common/input-password-toggle";
import {
  Col,
  CardTitle,
  CardText,
  FormGroup,
  Label,
  Button,
  Form,
} from "reactstrap";
import "@styles/base/pages/page-auth.scss";
import "@styles/react/libs/react-select/_react-select.scss";

const SetPassword = () => {
  const isFirstRunPassword = useRef(true);
  const isFirstRunConfirmPassword = useRef(true);

  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (isFirstRunPassword.current) {
      isFirstRunPassword.current = false;
      return;
    }
    !password
      ? setErrors({ ...errors, validPassword: true })
      : setErrors({ ...errors, validPassword: false });
  }, [password]);

  useEffect(() => {
    if (isFirstRunConfirmPassword.current) {
      isFirstRunConfirmPassword.current = false;
      return;
    }
    password.trim() !== confirmPassword.trim()
      ? setErrors({ ...errors, validConfirmPassword: true })
      : setErrors({ ...errors, validConfirmPassword: false });
  }, [confirmPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSubmitOtp = (e) => {
    e.preventDefault();
    alert(1);
  };

  return (
    <Col
      className='d-flex align-items-center auth-bg px-2 p-lg-2'
      lg='4'
      sm='12'
    >
      <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
        <CardTitle tag='h2' className='font-weight-bold mb-1'>
          Reset Password 🔐
        </CardTitle>

        <CardText className='mb-2'>
          Your new password must be different from previously used passwords
        </CardText>
        <Form
          className='auth-register-form mt-2'
          onSubmit={handleSubmitOtp}
        ></Form>
        <Form className='auth-register-form mt-2' onSubmit={handleSubmit}>
          <FormGroup>
            <Label className='form-label' for='password'>
              Password
            </Label>
            <InputPasswordToggle
              value={password}
              id='password'
              name='password'
              onChange={(e) => setPassword(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validPassword"],
              })}
            />
          </FormGroup>
          <FormGroup>
            <Label className='form-label' for='confirmPassword'>
              Confirm Password
            </Label>
            <InputPasswordToggle
              value={confirmPassword}
              id='confirmPassword'
              name='confirmPassword'
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validConfirmPassword"],
              })}
            />
          </FormGroup>
          <Button.Ripple
            disabled={!password || password.trim() !== confirmPassword.trim()}
            type='submit'
            block
            color='light'
          >
            Set New Password
          </Button.Ripple>
        </Form>
        <p className='text-center mt-2'>
          <span className='mr-25'>Already have an account?</span>
          <Link to='/login'>
            <span>Sign in instead</span>
          </Link>
        </p>
      </Col>
    </Col>
  );
};

export default SetPassword;
