import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import About from "./About";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/routes/routes.action";

const AboutUs = () => {
  const dispatch = useDispatch();
  const [sectionId, setSectionId] = useState(null);

  const getData = async () => {
    const sectionData = await API.getAllUiSection();
    const data = sectionData?.data?.data;
    const aboutId = data.filter((item) => item.title === "About")[0]._id;
    console.log("aboutId", aboutId);
    const dataFiltered = data.filter((item) => item.title === "About")[0];
    const about =
      dataFiltered.details.map((item) => {
        return {
          _id: item.title,
          title: item.title,
          description: item?.description,
          image: item.image,
          position: item.position,
        };
      }) || [];
    aboutId && dispatch(tableDataHandler({ aboutId, about }));
    setSectionId(aboutId);
  };

  useEffect(() => getData(), []);

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle>About</CardTitle>
            </CardHeader>
            <CardBody>
              <About getData={getData} sectionId={sectionId} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
