import { useSkin } from "@hooks/useSkin";
import { ChevronLeft } from "react-feather";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Col,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@styles/base/pages/page-auth.scss";
import { useEffect, useRef, useState } from "react";
import { isObjEmpty } from "@utils";
import toaster from "../../../common/Toaster";

const ForgotPasswordStepOne = () => {
  const isFirstRunPhone = useRef(true);
  const [skin, setSkin] = useSkin();
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (isFirstRunPhone.current) {
      isFirstRunPhone.current = false;
      return;
    }
    phone.length !== 10
      ? setErrors({ ...errors, validPhone: true })
      : setErrors({ ...errors, validPhone: false });
  }, [phone]);

  const handleSubmit = (data) => {
    // TODO: sent reset link

    if (isObjEmpty(errors)) {
      toaster("info", "Password reset Link sent");
    }
  };

  const illustration =
      skin === "dark"
        ? "forgot-password-v2-dark.svg"
        : "forgot-password-v2.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default;

  return (
    <>
      <Col
        className='d-flex align-items-center auth-bg px-2 p-lg-2'
        lg='4'
        sm='12'
      >
        <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
          <CardTitle tag='h2' className='font-weight-bold mb-1'>
            Forgot Password? 🔑
          </CardTitle>
          <CardText className='mb-2'>
            Enter your email and we'll send you instructions to reset your
            password.
          </CardText>
          <Form
            className='auth-forgot-password-form mt-2'
            onSubmit={handleSubmit}
          >
            <FormGroup>
              <Label className='form-label' for='forgot-phone'>
                Phone Number
              </Label>

              <Input
                autoFocus
                type='number'
                value={phone}
                id='forgot-phone'
                name='forgot-phone'
                placeholder='1234567890'
                onChange={(e) => setPhone(e.target.value)}
                className={classnames({
                  "is-invalid": errors && errors["validPhone"],
                })}
              />
            </FormGroup>
            <Button.Ripple
              disabled={phone.length !== 10}
              type='submit'
              color='light'
              block
            >
              Send Reset Link
            </Button.Ripple>
          </Form>
          <p className='text-center mt-2'>
            <Link to='/login'>
              <ChevronLeft className='mr-25' size={14} />
              <span className='align-middle'>Back to login</span>
            </Link>
          </p>
        </Col>
      </Col>
    </>
  );
};

export default ForgotPasswordStepOne;
