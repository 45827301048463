import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import RoutesSection from "@components/Routes";
import { useSelector, useDispatch } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Popular = ({getData}) => {
  const state = useSelector((state) => state?.routes);
  const [routesList, setRoutesList] = useState([]);
  const [allRoutes, setAllRoutes] = useState([]);
  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.popular && stored?.popular.length > 0) {
      setRoutesList(
        stored?.popular.map((item) => ({ ...item, id: item._id }))
      );
    }
    if(stored?.allRoutes && stored?.allRoutes.length > 0){
      setAllRoutes(stored?.allRoutes);
    }
  }, [stored]);

  return (
    <div className='mt-2'>
      <form >
        <Row>
          <RoutesSection
            getData={getData}
            routesList={routesList}
            isPopular={true}
            allRoutes={allRoutes}
            landingPage
          />
        </Row>
      </form>
    </div>
  );
};

export default Popular;
