import React from "react";
import { Table, Badge } from "reactstrap";
import { Col, Row } from "reactstrap";
import { dateFormatHandler } from "@utils";
import "./sidebar.scss";


const User = ({ tableHeaderList, selectedItemData = {}, headerTitle }) => {
  const getTableData = () => (
    <tbody>
      {Object.entries(tableHeaderList).map(([key, value]) => {
        const keyList = value.split(".");

        let queryKey;

        if (keyList.length === 1) {
          queryKey = selectedItemData[keyList[0]];
        } else if (keyList.length === 2 && selectedItemData[keyList[0]]) {
          queryKey = selectedItemData[keyList[0]]?.[keyList[1]];
        } else if (keyList.length === 3 && selectedItemData[keyList[0]] && [keyList[1]]) {
          queryKey = selectedItemData[keyList[0]]?.[keyList[1]]?.[keyList[2]];
        } else if (
          keyList.length === 4 &&
          selectedItemData[keyList[0]] && [keyList[1]] && [keyList[2]]
        ) {
          queryKey = selectedItemData[keyList[0]]?.[keyList[1]]?.[keyList[2]]?.[keyList[3]];
        }

        if (value === "status") {
          return (
            <tr>
              <td>{key}</td>
              <td key={key}>
                <Badge
                  pill
                  color={
                    queryKey === "pending"
                      ? "light-warning"
                      : queryKey === "confirmed"
                      ? "light-success"
                      : "light-danger"
                  }
                  className="mr-1"
                >
                  {queryKey?.toUpperCase()}
                </Badge>
              </td>
            </tr>
          );
        } else if (value === "departureDate" && queryKey) {
          return (
            <tr>
              <td>{key}</td>
              <td>{dateFormatHandler(queryKey)}</td>
            </tr>
          );
        }

        return (
          <tr>
            <td>{key}</td>
            <td key={key}>{queryKey}</td>
          </tr>
        );
      })}
    </tbody>
  );

  return (
    <Row>
      <Col>
          <h3 className="header">{headerTitle}</h3>
          <Table className="details-table" >
            <tbody>{getTableData()}</tbody>
          </Table>
      </Col>
    </Row>
  );
};

export default User;
