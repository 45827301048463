import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import AlertConfig from "./AlertConfig";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";

const HomeContentTabs = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const sectionData = await API.getAllUiSection();
    const data = sectionData?.data?.data;
    const flybigAlertsId = data.filter(
      (item) => item.title === "Flybig Alerts"
    )[0]._id;
    const flybigAlerts =
      data
        .filter((item) => item.title === "Flybig Alerts")[0]
        .details.map((item) => {
          return {
            description: item.title,
          };
        }) || [];
    flybigAlertsId &&
      dispatch(tableDataHandler({ flybigAlertsId, flybigAlerts }));
  };

  useEffect(() => getData(), []);

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle>Flybig Alerts</CardTitle>
            </CardHeader>
            <CardBody>
              <AlertConfig />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HomeContentTabs;
