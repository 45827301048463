import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getAllMedia = () =>
  axios.get("/media").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const getMediaById = () =>
  axios.get("/media/id").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const createMedia = (data) =>
  axios.post("/media", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const updateMedia = (data) =>
  axios.patch("/media", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

export default { getAllMedia, getMediaById, createMedia, updateMedia };
