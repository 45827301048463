import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row, Button } from "reactstrap";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import '@uppy/core/dist/style.css'
import '@uppy/file-input/dist/style.css'
import Uppy from "@uppy/core";
import { FileInput } from '@uppy/react';
import { Trash } from "react-feather";
import API from "@api";
import "./index.scss";
import toaster from "../common/Toaster";
import confirmDelete from "../common/confirmDelete";

const RoutesSection = ({ routesList, getData, isPopular = false, allRoutes = [] }) => {

  const [selectedRoute, setSelectedRoute] = useState(
    {
      origin: "",
      destination: "",
    }
  );
  const [destinations, setDestinations] = useState([]);

  const [addFormVisible, setAddFormVisible] = useState(false);

  const uppy = new Uppy({
    meta: { type: "image" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 2 * 1024 * 1024,
    },
  });

  uppy.on("complete", async (result) => {
    const id = result.successful[0].source;
    const formData = new FormData();
    const file = routesList.find((item) => item.id === id);
    formData.append("name", file?.name);
    formData.append("image", result.successful[0].data, file.id);
    const { data } = await API.uploadPopularImage(formData);
    if (data.success) {
      toaster("success","Image uploaded successfully");
    }
    getData();
  });
  const onConfirmDelete = async (id) => {
    const { data } = await API.deleteRoute(id);
    if (data.success) {
      toaster("success","Route deleted successfully");
      getData();
    }
  } 
  const handleRemove = (id) => {
    confirmDelete(() => onConfirmDelete(id));
  };

  const handleChange = (value, name) => {
    setSelectedRoute((prev) => ({ ...prev, [name]: value }));
    if(name === "origin" && value !== ""){
      const destinations = allRoutes.filter((item) => item.code === value)[0].destinations;
      setDestinations(destinations);
    }
    setSelectedRoute((prev) => ({ ...prev, [name]: value }));
  }
  
  useEffect(() => {
    if (allRoutes.length > 0) {
    setSelectedRoute((prev) => ({ ...prev, origin: allRoutes[0].code }));
    setDestinations(allRoutes[0].destinations);
    setSelectedRoute((prev) => ({ ...prev, destination: allRoutes[0].destinations[0].code }));
    }
  }, [allRoutes]);

  const handleSubmitHandler = async () => {
    const { data } = await API.createRoute({...selectedRoute, type: isPopular ? "popular" : "UDAAN"});
    if (data.success) {
      toaster("success","Route added successfully");
      getData();
    }
  };

  return (
    <div className='imageSection__container'>
      <div>
        <div className='imageSection__imagesContainer'>
          {routesList.map((item, index) => {
            return (
              <div key={index} className='imagesection__imageBox'>
                <div className='imageSection__addImage ml-2'>
                  <div className='imageSection__title'>
                    <h5>{item.origin} - {item.destination}</h5>
                    <Button.Ripple
                      className='btn-icon rounded-circle'
                      color='danger'
                      type='button'
                      size='sm'
                      onClick={() => handleRemove(item.id)}
                    >
                      <Trash size={14} />
                    </Button.Ripple>
                  </div>
                  {isPopular ? (
                    <img 
                    src={item.image}
                    alt='image'
                    className='imageSection__image'
                    />
                  ): null }
                  <br />
                  {isPopular ? (
                    <FileInput
                      uppy={uppy}
                      id={item.id}
                    />) : null}
                </div>
              </div>

            );
          })}
        </div>
        <hr />
        {addFormVisible ? (<form
          className='mt-2'
        >
          <Row className='mt-2'>
              <Col sm='12'>
                <FormGroup>
                  <FormGroup className='w-100'>
                    <Label className='form-label' for='origin'>
                      Origin
                    </Label>
                    <select
                      id='origin'
                      name='origin'
                      placeholder="Origin"
                      onChange={(e) => handleChange(e.target.value, "origin")}
                      className='form-control'
                    >
                      {allRoutes.map((item) => {
                        return (
                          <option key={item.code} value={item.code}>{item.city}</option>
                        )
                      })}
                    </select>
                  </FormGroup>
                  <FormGroup className='w-100'>
                    <Label className='form-label' for='destination'>
                      Destination
                    </Label>
                    <select
                      id='destination'
                      name='destination'
                      placeholder="Destination"
                      onChange={(e) => handleChange(e.target.value, "destination")}
                      className='form-control'
                    >
                      {destinations.map((item) => {
                        return (
                          <option key={item.code} value={item.code}>{item.city}</option>
                        )
                      })}
                    </select>
                  </FormGroup>
                </FormGroup>
              </Col>
              <Col sm='12' md='1'>
                <Button.Ripple
                  color='primary'
                  className='mt-2'
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmitHandler();
                  }
                }
                  size='sm'
                >
                  Add
                </Button.Ripple>
              </Col>
            </Row>
        </form>) : 
        <Button.Ripple
          color='primary'
          className='mt-2'
          type='button'
          onClick={(e) => {
            e.preventDefault();
            setAddFormVisible(true);
          }
        }
          size='sm'
        >
          Add New Route
        </Button.Ripple>}
      </div>
    </div>
  );
};

export default RoutesSection;
