import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getAllUsers = ({ limit, page, query = {} }) => {
  const searchQuery = new URLSearchParams({
    limit,
    page,
    ...query,
  });

  return axios.get(`/user?${searchQuery.toString()}`).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });
};

const getUserById = (id) =>
  axios.get(`/user/${id}`).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const downloadUserCSV = ({ query = {}, body }) => {
  const searchQuery = new URLSearchParams({
    limit: -1,
    page: 1,
    ...query,
  });
  return axios
    .post(`/user/downloadCSV?${searchQuery.toString()}`, body)
    .catch((error) => {
      console.log(error);
      toaster("error",error.message);
    });
};

export default { getAllUsers, getUserById, downloadUserCSV };
