import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "@components/common/ImageSection";
import API from "@api";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const HeroImage = () => {
  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);
  const dispatch = useDispatch();

  const stored = state?.tableData;

  useEffect(() => {
    if (stored?.heroImages && stored?.heroImages.length > 0) {
      setImageList(
        stored?.heroImages.map((item) => ({ ...item, id: item._id }))
      );
    }
  }, [stored]);

  const handleSubmitHandler = async (e) => {
    e.preventDefault();
    let isValid = true;
    let imageListClone = imageList;

    imageListClone?.forEach((item) => {
      isValid &&
        (item?.image && item?.key ? (isValid = true) : (isValid = false));
    });

    if (isValid) {
      imageListClone.forEach((item) => {
        delete item.id;
      });

      dispatch(
        tableDataHandler({
          ...stored,
          heroImages: imageListClone,
        })
      );

      const updated = await API.updateHomeContent({
        id: stored?._id,
        heroImages: imageListClone,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <div className='mt-2'>
      <form onSubmit={handleSubmitHandler}>
        <Row>
          <ImageSection
            imageList={imageList}
            setImageList={setImageList}
            landingPage
          />
        </Row>
        <SaveDiscardBtnGroup />
      </form>
    </div>
  );
};

export default HeroImage;
