import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "@components/common/ImageSection";
import API from "@api";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Images = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await API.getHomeContent();
    data && dispatch(tableDataHandler({ ...data.data, id: data.data._id }));
  };

  useEffect(() => getData(), []);

  const state = useSelector((state) => state?.cms);
  const [imageList, setImageList] = useState([]);

  const [stored, setStored] = useState(state?.tableData);
  useEffect(() => {
    setStored(state?.tableData);
  }, [state]);

  useEffect(() => {
    if (stored?.images && stored?.images.length > 0) {
      setImageList(stored?.images.map((item) => ({ ...item, id: item._id })));
    }
  }, [stored]);

  const handleSubmitHandler = async (e) => {
    e.preventDefault();
    let isValid = true;
    let dataClone = imageList;

    dataClone?.forEach((item) => {
      isValid && (item?.image ? (isValid = true) : (isValid = false));
    });

    if (isValid) {
      dataClone.forEach((item) => {
        delete item.id;
      });
      dispatch(
        tableDataHandler({
          ...stored,
          images: dataClone,
        })
      );
      const updated = await API.updateHomeContent({
        id: stored?._id,
        images: dataClone,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };
  return (
    <Row>
      <Col>
        <Card>
          <CardHeader className="border-bottom">
            <CardTitle>Images</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="mt-2">
              <form onSubmit={handleSubmitHandler}>
                <Row>
                  <ImageSection
                    imageList={imageList}
                    setImageList={setImageList}
                    actions={{
                      view: true,
                      copy: true,
                    }}
                  />
                </Row>
                <SaveDiscardBtnGroup />
              </form>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Images;
