import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import FAQ from "./Faq";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/routes/routes.action";

const FAQs = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const sectionData = await API.getAllUiSection();
    const data = sectionData?.data?.data;
    const faqId = data.filter((item) => item.title === "FAQ")[0]._id;
    const dataFiltered = data.filter((item) => item.title === "FAQ")[0];
    const faq =
      dataFiltered.details.map((item) => {
        return {
          _id: item.title,
          title: item?.title || "",
          extraDetails: {
            questionAndAnswers: item.extraDetails?.questionAndAnswers || [],
          },
        };
      }) || [];
    faqId && dispatch(tableDataHandler({ faqId, faq }));
  };

  useEffect(() => getData(), []);

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle>FAQs</CardTitle>
            </CardHeader>
            <CardBody>
              <FAQ getData={getData} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default FAQs;
