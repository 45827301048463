import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
// import BannerImageSection from "@components/common/BannerImageSection";
import ImageSection from "../../../@core/components/common/ImageSection";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import { useSelector, useDispatch } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import toaster from "@components/common/Toaster";
import API from "@api";

const BannerImage = ({ getData }) => {
  const state = useSelector((state) => state?.cms);
  const [bannerList, setBannerList] = useState([]);
  const uppy = new Uppy({
    meta: { type: "banner" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1 * 1024 * 1024,
    },
  });
  uppy.on("complete", async (result) => {
    const id = result.successful[0].source;
    const formData = new FormData();
    const file = bannerList.find((item) => item.id === id);
    formData.append("name", file?.name);
    formData.append("image", result.successful[0].data, `${file.title}`);
    const { data } = await API.uploadBannerImage(formData);
    if (data.success) {
      toaster("success", "Image file uploaded successfully");
    }
    await getData();
  });
  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.bannerImages && stored?.bannerImages.length > 0) {
      setBannerList(
        stored?.bannerImages.map((item) => ({ ...item, id: item._id }))
      );
    }
  }, [stored, state]);

  return (
    <div className="mt-2">
      <form>
        <Row>
          <ImageSection
            imageList={bannerList}
            setImageList={setBannerList}
            source="BannerImage"
            actions={{ new: false, delete: false, view: true }}
            uploadUppy={uppy}
            updateUppy={uppy}
            landingPage
          />
        </Row>
      </form>
    </div>
  );
};

export default BannerImage;
