import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import { FileInput } from "@uppy/react";
import API from "@api";
import "./index.scss";
import toaster from "../common/Toaster";
import SaveDiscardBtnGroup from "../common/save-discard-btn-group";
import { v4 as uuidv4 } from "uuid";
import { tableDataHandler } from "@store/Cms/cms.action";

const SpecialOfferSection = ({ dataList, getData }) => {
  const stored = useSelector((state) => state?.cms?.tableData);
  const dispatch = useDispatch();

  const [offers, setOffers] = useState([
    {
      id: uuidv4(),
      title: "",
      description: "",
      image: "",
      extraDetails: {
        tooltip: "",
        color: "",
      },
    },
  ]);

  const uppy = new Uppy({
    meta: { type: "image" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: [".svg"],
      maxFileSize: 1 * 1024 * 1024,
    },
  });

  uppy.on("complete", async (result) => {
    const id = result.successful[0].source;
    const formData = new FormData();
    const file = offers.find((item) => item.id === id);
    formData.append("name", file?.name);
    formData.append("image", result.successful[0].data, `${file.title}${file.id}`);
    const { data } = await API.uploadImage(formData);
    if (data.success) {
      toaster("success", "Image uploaded successfully");
    }
    const { url } = data.data;
    setOffers(
      offers.map((item) => (item.id === id ? { ...item, image: url } : item))
    );
  });
  useEffect(() => {
    if (stored?.specialOffer && stored?.specialOffer?.length > 0) {
      setOffers(
        stored?.specialOffer.map((item) => {
          return {
            id: item._id || uuidv4(),
            title: item?.title,
            description: item?.description,
            image: item?.image.link,
            extraDetails: {
              tooltip: item?.extraDetails?.tooltip,
              color: item?.extraDetails?.color,
            },
          };
        })
      );
    }
  }, [stored]);

  const handleChange = (val, id, key) => {
    let value = val;
    if (key === 'extraDetails'){
      value = { ...offers.find((item) => item.id === id)[key], ...val };
    }
    setOffers(
      offers.map((item) => (item.id === id ? { ...item, [key]: value } : item))
    );
  }

  const handleAddMedia = () =>
    setOffers([
      ...offers,
      {
        id: uuidv4(),
        title: "",
        description: "",
        image: "",
        extraDetails: { tooltip: "", color: "" },
      },
    ]);

  const handleRemoveMedia = (id) => {
    let newMediaData = offers.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    setOffers(newMediaData);
  };

  const handleSubmitHandler = async (e) => {
    e.preventDefault();

    let isValid = true;
    let offersClone = offers;

    offersClone?.forEach((item) => {
      isValid &&
        (item?.description ? (isValid = true) : (isValid = false)) &&
        (item?.title ? (isValid = true) : (isValid = false));
    });

    if (isValid) {
      offersClone.forEach((item) => {
        delete item.id;
      });
      dispatch(
        tableDataHandler({
          ...stored,
          SpecialOffers: offersClone,
        })
      );
      toaster("success", "Data saved successfully");
      const res = offersClone.map((item) => {
        return {
          ...item,
          description: {
            text: item.description,
          },
          image: {
            link: item.image,
          },
        };
      });
      const updated = await API.updateUiSectionById(stored?.specialOfferId, {
        details: res,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
      getData();
      // reload
      window.location.reload();
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <div className="imageSection__container">
      <form
        className="mt-2"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitHandler(e);
        }}
      >
        {offers.length &&
          offers.map(
            ({ id, title, description, image, extraDetails }, index) => {
              return (
                <Row className="mt-2" key={id}>
                  <Col sm="12" md="11">
                    <FormGroup className="w-100">
                      <Label className="form-label" for="description">
                        Title
                      </Label>

                      <Input
                        type="text"
                        value={title}
                        id="title"
                        name="title"
                        placeholder="title"
                        onChange={(e) =>
                          handleChange(e.target.value, id, e.target.name)
                        }
                      />
                    </FormGroup>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="description">
                        Description
                      </Label>

                      <Input
                        type="text"
                        value={description}
                        id="description"
                        name="description"
                        placeholder="Description"
                        onChange={(e) =>
                          handleChange(e.target.value, id, e.target.name)
                        }
                      />
                    </FormGroup>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="tooltip">
                        Tooltip
                      </Label>
                      <Input
                        type="text"
                        value={extraDetails?.tooltip}
                        id="tooltip"
                        name="tooltip"
                        placeholder="Tooltip"
                        onChange={(e) =>
                          handleChange(
                            { tooltip: e.target.value },
                            id,
                            "extraDetails"
                          )
                        }
                      />
                    </FormGroup>
                    <FormGroup className="w-100">
                      <Label className="form-label" for="color">
                        Color
                      </Label>
                      <Input
                        type="text"
                        value={extraDetails?.color}
                        id="color"
                        name="color"
                        placeholder="use hex code for color ex. #123123"
                        onChange={(e) =>
                          handleChange(
                            { color: e.target.value },
                            id,
                            "extraDetails"
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <div key={index} className="imagesection__imageBox">
                    <div className="imageSection__addImage ml-2">
                      {image ? (
                        <img
                          src={image}
                          alt="image"
                          className="imageSection__image"
                        />
                      ) : (
                        <p>No Image</p>
                      )}
                      <FileInput uppy={uppy} id={id} />
                      {offers.length > 1 ? (
                      <>
                        <Button.Ripple
                          className="btn-icon mb-1 mr-1"
                          color="danger"
                          type="button"
                          size="md"
                          onClick={() => handleRemoveMedia(id)}
                        > Remove
                        </Button.Ripple>
                      </>
                    ) : null}
                    </div>
                  </div>
                </Row>
              );
            }
          )}
        <SaveDiscardBtnGroup addAction={handleAddMedia} />
      </form>
    </div>
  );
};

export default SpecialOfferSection;
