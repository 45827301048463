import React from "react";
import { Row, Col } from "reactstrap";
import "@styles/base/pages/page-auth.scss";
import styled from "styled-components";
import { colorPalette } from "../../../../../styles/colorPalette";
import FlybigLogo from "../../../../../assets/images/icons/flybig_logo.png";

const AuthWrapper = ({ source, children }) => {
  return (
    <div className='auth-wrapper auth-v2'>
      <Row className='auth-inner m-0 pb-0'>
        <StyledColWrap
          className='d-none d-lg-flex align-items-center p-5'
          lg='8'
          sm='12'
        >
          <img className='flybig-logo' src={FlybigLogo} alt='Flybig' />
          <div className='w-100 d-lg-flex align-items-center justify-content-center px-5'>
            <img className='img-fluid' src={source} alt='Login V2' />
          </div>
        </StyledColWrap>
        {children}
      </Row>
    </div>
  );
};

const StyledColWrap = styled(Col)`
  background-color: ${colorPalette.$primary};
  .flybig-logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
`;

export default AuthWrapper;
