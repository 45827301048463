import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Offers from "./Offers";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/routes/routes.action";

const SpecialOffer = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const sectionData = await API.getAllUiSection();
    const data = sectionData?.data?.data;
    const specialOfferId = data.filter(
      (item) => item.title === "Special Offers"
    )[0]._id;
    const dataFiltered = data.filter(
      (item) => item.title === "Special Offers"
    )[0];
    const specialOffer =
      dataFiltered.details.map((item) => {
        return {
          _id: item.title,
          title: item.title,
          description: item?.description?.text || "",
          image: item.image || "",
          extraDetails: {
            tooltip: item.extraDetails.tooltip || "",
            color: item.extraDetails.color || "",
          },
        };
      }) || [];
    specialOfferId &&
      dispatch(tableDataHandler({ specialOfferId, specialOffer }));
  };

  useEffect(() => getData(), []);

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle>Special Offers</CardTitle>
            </CardHeader>
            <CardBody>
              <Offers getData={getData} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SpecialOffer;
