const removeButtons = [
  "Cut",
  "Copy",
  "Paste",
  "PasteText",
  "PasteFromWord",
  "-",
  "Undo",
  "Redo",
  "Source",
  "Find",
  "Replace",
  "-",
  "SelectAll",
  "-",
  "SpellChecker",
  "Scayt",
  "Anchor",
  "Table",
  "About",
  "Flash",
  "HorizontalRule",
  "Smiley",
  "SpecialChar",
  "PageBreak",
  "Iframe",
];

export default removeButtons;
