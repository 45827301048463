import { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Label,
  Input,
  Button,
} from "reactstrap";
import toaster from "@components/common/Toaster";

const EmailPopup = ({ showModal, closeModal, downloadCSVFile }) => {
  const [email, setEmail] = useState("");
  const handleClose = () => {
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      return toaster("error", "Please add an email");
    }
    downloadCSVFile(email);
    toaster(
      "success",
      `We will send you the report on ${email} in next 5 mins`
    );
    setEmail("");
    closeModal();
  };

  return (
    <Modal
      isOpen={showModal}
      toggle={handleClose}
      className="modal-dialog-centered modal-lg"
    >
      <ModalHeader
        className="bg-transparent"
        toggle={handleClose}
      ></ModalHeader>
      <ModalBody className="px-sm-5 mx-50 pb-5">
        <div className="text-center mb-2">
          <h3>Email to send the Report</h3>
        </div>
        <Row tag="form" className="gy-1 pt-75">
          <Col xs={12}>
            <Label className="form-label" for="link">
              Email
            </Label>

            <Input
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
              required
            />
          </Col>

          <Col xs={12} className="text-center mt-2">
            <Button onClick={handleSubmit} className="me-1" color="light mr-3">
              Proceed
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={handleClose}
            >
              Discard
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default EmailPopup;
