import toast from "react-hot-toast";

const toaster = (type, message) => {
  type = type ? type : "error";
  message = message ? message : "Something went wrong";

  toast[type](message);
};

export default toaster;
