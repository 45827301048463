import booking from "./booking";
import user from "./user";
import cms from "./cms";
import faq from "./faq";
import routes from "./routes";
import uploadImages from "./uploadImages";

export default [
  ...booking,
  ...user,
  ...cms,
  ...routes,
  ...uploadImages,
  ...faq,
];
