import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import TacSection from "./TAC";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/routes/routes.action";

const TAC = () => {
  const dispatch = useDispatch();
  const [sectionId, setSectionId] = useState(null);

  const getData = async () => {
    const sectionData = await API.getAllUiSection();
    const data = sectionData?.data?.data;
    const tacId = data.filter((item) => item.title === "Terms & Conditions")[0]
      ._id;
    const dataFiltered = data.filter(
      (item) => item.title === "Terms & Conditions"
    )[0];
    const tac =
      dataFiltered.details.map((item) => {
        return {
          _id: item.title,
          title: item.title,
          description: item?.description,
        };
      }) || [];
    tacId && dispatch(tableDataHandler({ tacId, tac }));
    setSectionId(tacId);
  };

  useEffect(() => getData(), []);

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle>Terms & Conditions</CardTitle>
            </CardHeader>
            <CardBody>
              <TacSection getData={getData} sectionId={sectionId} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};


export default TAC;
