import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import PrivacySection from "./Privacy";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/routes/routes.action";

const Privacy = () => {
  const dispatch = useDispatch();
  const [sectionId, setSectionId] = useState(null);

  const getData = async () => {
    const sectionData = await API.getAllUiSection();
    const data = sectionData?.data?.data;
    const privacyId = data.filter((item) => item.title === "Privacy")[0]._id;
    const dataFiltered = data.filter((item) => item.title === "Privacy")[0];
    const privacy =
      dataFiltered.details.map((item) => {
        return {
          _id: item.title,
          title: item.title,
          description: item?.description,
        };
      }) || [];
    privacyId && dispatch(tableDataHandler({ privacyId, privacy }));
    setSectionId(privacyId);
  };

  useEffect(() => getData(), []);

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <CardTitle>Privacy</CardTitle>
            </CardHeader>
            <CardBody>
              <PrivacySection getData={getData} sectionId={sectionId} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Privacy;
