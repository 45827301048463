import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getAllUiSection = () =>
  axios.get("/ui-sections").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const getUiSectionById = () =>
  axios.get("/ui-sections/id").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const createUiSection = (data) =>
  axios.post("/ui-sections", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const updateUiSection = (data) =>
  axios.patch("/ui-sections", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const updateUiSectionById = (id, data) =>
  axios.patch(`/ui-sections/${id}`, data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const uploadBannerImage = (data) =>
  axios.post("/ui-sections/uploadBannerImage", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const uploadSectionImage = (data, sectionId) =>
  axios.post(`/ui-sections/uploadSectionImage/${sectionId}`, data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const uploadImage = (data) => 
  axios.post("/ui-sections/uploadImage", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });


export default { getAllUiSection, getUiSectionById, createUiSection, updateUiSection, uploadBannerImage, updateUiSectionById, uploadSectionImage, uploadImage };
