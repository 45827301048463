import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getAllBlogs = () =>
  axios.get("/blogs").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const getBlogById = () =>
  axios.get("/blogs/id").catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const createBlog = (data) =>
  axios.post("/blogs", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

const updateBlog = (data) =>
  axios.patch("/blogs", data).catch((error) => {
    toaster("error", error?.response?.data?.message);
  });

export default { getAllBlogs, getBlogById, createBlog, updateBlog };
