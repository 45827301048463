import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Pdf from "./Pdf";
import { useDispatch } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";


const PdfUpload = () => {
  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await API.getHomeContent();
    const pdfFiles = await API.getAllPdfFile();
    data && dispatch(tableDataHandler({...data.data, pdfFiles: pdfFiles.data.data}));
  };

  useEffect(() => getData(), []);

  return (
    <div>
        <Row>
          <Col>
            <Card>
              <CardHeader className='border-bottom'>
                <CardTitle>
                  Home Content - PDF Upload
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Pdf getData={getData}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
  );
};

export default PdfUpload;

