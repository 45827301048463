import toaster from "../../@core/components/common/Toaster";
import axios from "../../configs/axios";

const getAllBookings = ({ limit, page, query = {} }) => {
  const searchQuery = new URLSearchParams({
    limit,
    page,
    ...query,
  });
  return axios.get(`/booking?${searchQuery.toString()}`).catch((error) => {
    toaster.error(error.message);
  });
};

const getBookingById = (id) =>
  axios.get(`/booking/${id}`).catch((error) => {
    toaster.error(error.message);
  });

const downloadBookingCSV = ({ query = {}, body }) => {
  const searchQuery = new URLSearchParams({
    limit: -1,
    page: 1,
    ...query,
  });
  return axios
    .post(`/booking/downloadCSV?${searchQuery.toString()}`, body)
    .catch((error) => {
      toaster("error", error.message);
    });
};

export default { getAllBookings, getBookingById, downloadBookingCSV };
