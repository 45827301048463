import React from "react";
import Loader from "react-js-loader";
import "./styles.scss";

const AppLoader = () => {
  return (
    <div className='loader__container'>
      <div className='loader' />
      <Loader
        type='bubble-loop'
        bgColor={"#E53D74"}
        color={"#202020"}
        size={140}
      />
    </div>
  );
};

export default AppLoader;
