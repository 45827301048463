import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SpecialOfferSection from "@components/SpecialOfferSection";
import { useSelector, useDispatch } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const Offers = ({getData}) => {
  const state = useSelector((state) => state?.cms);
  const [specialOfferList, setSpecialOffer] = useState([]);
  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.specialOffer && stored?.specialOffer.length > 0) {
      setSpecialOffer(
        stored?.specialOffer.map((item) => ({ ...item, id: item._id }))
      );
    }
  }, [stored]);

  return (
    <div className='mt-2'>
      <Row>
        <SpecialOfferSection
          getData={getData}
          dataList={specialOfferList}
          landingPage
        />
      </Row>
    </div>
  );
};

export default Offers;
