import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import ImageSection from "@components/common/ImageSection";
import API from "@api";
import { useSelector, useDispatch } from "react-redux";
import toaster from "@components/common/Toaster";
import { tableDataHandler } from "@store/Cms/cms.action";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";

const HeroImage = () => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const state = useSelector((state) => state?.cms);
  const [blogList, setBlogList] = useState([]);
  const dispatch = useDispatch();
  const stored = state?.tableData;

  useEffect(() => {
    if (stored?.blogs && stored?.blogs.length > 0) {
      setBlogList(stored?.blogs.map((item) => ({ ...item, id: item._id })));
    }
  }, [stored]);

  useEffect(() => {
    const oldBlogList = JSON.stringify(stored?.blogs.map((item) => ({ ...item, id: item._id })));
    if(oldBlogList !== JSON.stringify(blogList)) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [blogList]);

  const handleSubmitHandler = async (e) => {
    e.preventDefault();
    let isValid = true;
    let dataClone = blogList;

    dataClone?.forEach((item) => {
      isValid &&
        (item?.image && item?.key && item.link
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      dataClone.forEach((item) => {
        delete item.id;
      });

      dispatch(
        tableDataHandler({
          ...stored,
          blogs: dataClone,
        })
      );

      const updated = await API.updateHomeContent({
        id: stored?._id,
        blogs: dataClone,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };
  return (
    <div className='mt-2'>
      <form onSubmit={handleSubmitHandler}>
        <Row>
          <ImageSection
            imageList={blogList}
            setImageList={setBlogList}
            limit={5}
            isImageLink
          />
        </Row>
        <SaveDiscardBtnGroup disabled={saveDisabled}/>
      </form>
    </div>
  );
};

export default HeroImage;
