import User from "../../views/User";

const UserRoutes = [
  {
    path: "/users",
    exact: true,
    component: User,
  },
];

export default UserRoutes;
