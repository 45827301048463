import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import SpecialOfferSection from "@components/SpecialOfferSection";
import { useSelector, useDispatch } from "react-redux";

import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import UiSection from "@components/common/newUISection";

const Contact = ({ getData, sectionId }) => {
  const state = useSelector((state) => state?.cms);
  const [contact, setContact] = useState([]);
  const stored = state?.tableData;
  useEffect(() => {
    if (stored?.contact && stored?.contact.length > 0) {
      setContact(stored?.contact.map((item) => ({ ...item, id: item._id })));
    }
  }, [stored]);

  return (
    <div className="mt-2">
      <Row>
        <UiSection
          dataList={contact}
          getData={getData}
          sectionName="contact"
          sectionId={sectionId}
        />
      </Row>
    </div>
  );
};

export default Contact;
