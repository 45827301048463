import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import SaveDiscardBtnGroup from "@components/common/save-discard-btn-group";
import Flatpickr from "react-flatpickr";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import API from "@api";
import { tableDataHandler } from "@store/Cms/cms.action";
import toaster from "@components/common/Toaster";

const Media = () => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const state = useSelector((state) => state?.cms);
  const dispatch = useDispatch();

  const [media, setMedia] = useState([
    {
      id: uuidv4(),
      title: "",
      link: "",
      date: new Date(),
    },
  ]);
  const [dateObj, setDateObj] = useState({ id: null, value: new Date() });

  const stored = state?.tableData;

  useEffect(() => {
    if (stored?.media && stored?.media?.length > 0) {
      setMedia(
        stored?.media.map((item) => ({
          id: uuidv4(),
          title: item?.title,
          link: item?.link,
          date: item?.date,
        }))
      );
    }
  }, [stored?.media]);

  useEffect(() => {
    let updated = true;
    if (media && stored?.media && media.length === stored.media.length) {
      updated = false;
      stored?.media.forEach((item, index) => {
        if (
          !(
            item.title === media[index].title &&
            item.link === media[index].link &&
            item.date === media[index].date
          )
        ) {
          updated = true;
          return;
        }
      });
    }
    setSaveDisabled(!updated);
  }, [media]);

  useEffect(() => {
    dateObj.id && handleChange(dateObj.value, dateObj.id, "date");
  }, [dateObj]);

  const handleChange = (val, id, key) =>
    setMedia(
      media.map((item) => (item.id === id ? { ...item, [key]: val } : item))
    );

  const handleAddMedia = () =>
    setMedia([
      ...media,
      { id: uuidv4(), title: "", link: "", date: new Date() },
    ]);

  const handleRemoveMedia = (id) => {
    let newMediaData = media.filter((item) => {
      if (item.id !== id) {
        return true;
      }
    });
    setMedia(newMediaData);
  };

  const handleSubmitHandler = async () => {
    let isValid = true;
    let mediaClone = media;

    mediaClone?.forEach((item) => {
      isValid &&
        (item?.title && item?.link && item?.date
          ? (isValid = true)
          : (isValid = false));
    });

    if (isValid) {
      mediaClone.forEach((item) => {
        delete item.id;
      });

      dispatch(
        tableDataHandler({
          ...stored,
          media: mediaClone,
        })
      );

      const updated = await API.updateHomeContent({
        id: stored?._id,
        media: mediaClone,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <form
      className="mt-2"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitHandler();
      }}
    >
      {media.length &&
        media.map(({ id, title, link, date }) => (
          <div key={id}>
            <Row className="mt-2">
              <Col sm="12" md="11">
                <FormGroup>
                  <FormGroup className="w-100">
                    <Label className="form-label" for="title">
                      Title
                    </Label>

                    <Input
                      type="text"
                      value={title}
                      id="title"
                      name="title"
                      placeholder="Title"
                      onChange={(e) =>
                        handleChange(e.target.value, id, e.target.name)
                      }
                    />
                  </FormGroup>

                  <FormGroup className="w-100">
                    <Label className="form-label" for="link">
                      Link
                    </Label>

                    <Input
                      rows={4}
                      type="text"
                      value={link}
                      id="link"
                      name="link"
                      placeholder="Link"
                      onChange={(e) =>
                        handleChange(e.target.value, id, e.target.name)
                      }
                    />
                  </FormGroup>
                  <FormGroup className="w-100">
                    <Label className="form-label" for="date">
                      Date
                    </Label>

                    <Flatpickr
                      className="form-control"
                      value={date}
                      id="date"
                      onChange={(value) => setDateObj({ id, value })}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col sm="12" md="1">
                {media.length > 1 ? (
                  <>
                    <Button.Ripple
                      className="btn-icon rounded-circle mb-1 mr-1"
                      color="danger"
                      type="button"
                      size="sm"
                      onClick={() => handleRemoveMedia(id)}
                    >
                      <Trash size={14} />
                    </Button.Ripple>
                  </>
                ) : null}
              </Col>
            </Row>
          </div>
        ))}

      <SaveDiscardBtnGroup addAction={handleAddMedia} disabled={saveDisabled} />
    </form>
  );
};

export default Media;
