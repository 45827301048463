import React, { useEffect, useState } from "react";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import Uppy from "@uppy/core";
import { DragDrop } from "@uppy/react";
import { X, Link, Eye, Copy, Edit } from "react-feather";
import UrlForm from "./UrlForm";
import confirmDelete from "@components/common/confirmDelete";
import toaster from "@components/common/Toaster";
import API from "@api";
import { v4 as uuidv4 } from "uuid";
import "@styles/react/libs/file-uploader/file-uploader.scss";

import "./index.scss";

const ImageSection = ({
  imageList,
  setImageList,
  isImageLink = false,
  limit = -1,
  actions,
  source = "",
  uploadUppy,
  updateUppy,
}) => {
  //default actions
  actions = {
    view: false,
    copy: false,
    change: true,
    delete: true,
    new: true,
    ...actions,
  };
  const [imageLink, setImageLink] = useState({});

  const [showModal, setShowModal] = useState(false);

  const uppy = new Uppy({
    meta: { type: "avatar" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 1024 * 1024,
    },
  });

  uppy.on("complete", async (result) => {
    const formData = new FormData();
    const fileName = `${source}-${Date.now()}-${Math.round(
      Math.random() * 1e9
    )}`;
    formData.append("image", result.successful[0].data, fileName);
    const { data } = await API.uploadImage(formData);
    const randomId =
      data.data.id || data.data._id || data?.data?.key
        ? data.data.id || data.data._id || data?.data?.key
        : uuidv4();
    const newImage = {
      key: data?.data?.key,
      image: data?.data?.url,
      id: randomId,
    };

    imageLink.link && (newImage.link = imageLink.link);

    if (data.success) {
      setImageList((prev) => [newImage, ...prev]);
    }
  });

  const uppyForUpdate = new Uppy({
    meta: { type: "avatarUpdate" },
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes: ["image/*", ".jpg", ".jpeg", ".png"],
      maxFileSize: 0.5 * 1024 * 1024,
    },
  });

  uppyForUpdate.on("complete", async (result) => {
    const id = result.successful[0].source;
    const formData = new FormData();
    const file = imageList.find((item) => item.id === id);
    const linkArray = file.image.split("/");
    let fileName = `${linkArray[linkArray.length - 1] || file.title || id}`;
    formData.append("name", file?.name);
    formData.append("image", result.successful[0].data, fileName);
    const { data } = await API.uploadImage(formData);
    window.location.reload();
    if (data.success) {
      toaster("success", "Image file uploaded successfully");
    }
  });

  const removeImages = (image) => {
    const newSet = imageList.filter((item) => {
      const imgUrl = item.image;
      return imgUrl !== image;
    });

    setImageList(newSet);
  };

  const handleRemoveImage = (image) => {
    confirmDelete(() => removeImages(image));
  };

  const openModal = (index, link) => {
    setShowModal(true);
    setImageLink({ index, link });
  };

  const closeModal = () => {
    setShowModal(false);
    setImageLink({});
  };

  const addImageLink = (index, link) => {
    let newImageList = [...imageList];
    const imgWithLink = { ...newImageList[index], link };

    newImageList[index] = imgWithLink;
    setImageList(newImageList);
  };

  return (
    <div className="imageSection__container">
      <UrlForm
        showModal={showModal}
        closeModal={closeModal}
        setShowModal={setShowModal}
        imageLink={imageLink}
        addImageLink={addImageLink}
      />

      <div>
        <h3 className="imageSection__header mt-2">Upload images</h3>
        <div className="imageSection__imagesContainer">
          {imageList.map((item, index) => {
            return (
              <div key={index} className="imagesection__imageBox">
                {item.title && <h5>{item.title}</h5>}
                  <img
                    className="imageSection__image"
                    src={
                      source === "BannerImage" ? item.bannerImage : item.image
                    }
                    alt="avatar"
                  />
                <div className="imageSection_footer">
                  {item.title ? (
                    ""
                  ) : (
                    <div className="imageSection_icon imageSection__index">
                      {index + 1}
                    </div>
                  )}
                  {isImageLink && (
                    <div
                      onClick={() => openModal(index, item.link)}
                      className="imageSection_icon imageSection__link"
                    >
                      <Link size={25} />
                    </div>
                  )}
                  {actions.view && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          source === "BannerImage"
                            ? item.bannerImage
                            : item.image,
                          "_blank"
                        );
                      }}
                      className="imageSection_icon imageSection__link"
                    >
                      <Eye size={25} />
                    </div>
                  )}
                  {actions.copy && (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(item.image);
                        toaster("success", "Link Copied");
                      }}
                      className="imageSection_icon imageSection__copy"
                    >
                      <Copy size={25} />
                    </div>
                  )}
                  {actions.change && item.id && (
                    <div className="imageSection_icon imageSection__update">
                      <DragDrop
                        id={item.id || item._id}
                        className="updateDragDrop"
                        locale={{
                          strings: {
                            dropHereOr: "",
                          },
                        }}
                        uppy={updateUppy ? updateUppy : uppyForUpdate}
                      />
                    </div>
                  )}
                  {actions.delete && (
                    <div
                      onClick={() => handleRemoveImage(item.image)}
                      className=" imageSection_icon imageSection__removeImage"
                    >
                      <X size={25} />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          {actions.new && (limit === -1 || imageList.length < limit) ? (
            <div className="imageSection__addImage ml-2">
              <DragDrop
                id="heroImage"
                width="12em"
                height="12em"
                locale={{
                  strings: {
                    dropHereOr: "Max 1 MB",
                  },
                }}
                uppy={uploadUppy ? updateUppy : uppy}
              />
            </div>
          ) : null}
        </div>
        <hr />
      </div>
    </div>
  );
};

export default ImageSection;
