import { useEffect, useState } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InputPasswordToggle from "@components/common/input-password-toggle";
import {
  Col,
  CardTitle,
  CardText,
  Form,
  Input,
  FormGroup,
  Label,
  CustomInput,
  Button,
  FormFeedback,
} from "reactstrap";
import API from "@api";
import "@styles/base/pages/page-auth.scss";
import { userSetDetails } from "@store/user/user.action";
import { emailValidationHandler } from "@utils";
import toaster from "../../../common/Toaster";

const LoginStepFinal = ({ dispatchUserSetDetails }) => {
  const [errors, setErrors] = useState(null);
  const [email, setEmail] = useState("admin@flybig.com");
  const [inValidEmail, setInvalidEmail] = useState(true);
  const [password, setPassword] = useState("admin");
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    let isEmailValid = emailValidationHandler(email);
    setInvalidEmail(!isEmailValid);
    setErrors({ ...errors, validEmail: !isEmailValid });
  }, [email]);

  useEffect(() => {
    !password
      ? setErrors({ ...errors, validPassword: true })
      : setErrors({ ...errors, validPassword: false });
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await API.login({ email, password });

    if (res?.data?.success) {
      const { token, ...user } = res?.data?.data;

      dispatchUserSetDetails({
        token: token,
        userDetails: user,
      });

      toaster("success", `Welcome ${res?.data?.data?.name}`);
    }
  };

  return (
    <Col
      className='d-flex align-items-center auth-bg px-2 p-lg-2'
      lg='4'
      sm='12'
    >
      <Col className='px-xl-2 mx-auto' sm='8' md='6' lg='12'>
        <CardTitle tag='h2' className='font-weight-bold mb-1'>
          Welcome to Flybig! 👋🏻
        </CardTitle>
        <CardText className='mb-2'>
          Please sign-in to your account and start the adventure
        </CardText>
        <Form className='auth-login-form mt-2' onSubmit={handleSubmit}>
          <FormGroup>
            <Label className='form-label' for='login-phone'>
              Email
            </Label>

            <Input
              autoFocus
              type='email'
              value={email}
              id='login-phone'
              name='login-phone'
              placeholder='demo@demo.com'
              onChange={(e) => setEmail(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validEmail"],
              })}
            />
            {errors && errors["validEmail"] && (
              <FormFeedback>Plese enter a valid email</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <div className='d-flex justify-content-between'>
              <Label className='form-label' for='login-password'>
                Password
              </Label>
              <Link to='/forgot-password'>
                <small>Forgot Password?</small>
              </Link>
            </div>
            <InputPasswordToggle
              value={password}
              id='login-password'
              name='login-password'
              onChange={(e) => setPassword(e.target.value)}
              className={classnames({
                "is-invalid": errors && errors["validPassword"],
              })}
            />
            {errors && errors["validPassword"] && (
              <FormFeedback>Plese enter a valid password</FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <CustomInput
              type='checkbox'
              checked={checked}
              className='custom-control-Primary'
              id='remember-me'
              label='Remember Me'
              onChange={() => setChecked(!checked)}
            />
          </FormGroup>
          <Button.Ripple
            disabled={inValidEmail || !password}
            type='submit'
            color='light'
            block
          >
            Sign in
          </Button.Ripple>
        </Form>
      </Col>
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchUserSetDetails: (data) => dispatch(userSetDetails(data)),
});

export default connect(null, mapDispatchToProps)(LoginStepFinal);
