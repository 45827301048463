import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import "uppy/dist/uppy.css";
import "@uppy/status-bar/dist/style.css";
import "@styles/react/libs/file-uploader/file-uploader.scss";
import API from "@api";
import "./index.scss";
import toaster from "../Toaster";
import SaveDiscardBtnGroup from "../save-discard-btn-group";
import { Trash } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { tableDataHandler } from "@store/Cms/cms.action";
import removeButtons from "../../../../configs/ckeditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UiSection = ({ dataList, getData, sectionName, sectionId }) => {
  const state = useSelector((state) => state?.cms);

  const dispatch = useDispatch();
  const stored = state?.tableData;
  const sectionData = stored ? stored[sectionName] : [];

  const [data, setData] = useState([]);

  useEffect(() => {
    if (sectionData && sectionData?.length > 0) {
      setData(() =>
        sectionData.map((item, index) => {
          return {
            id: uuidv4(),
            title: item?.title,
            description: item?.description?.text || "",
          };
        })
      );
    }
  }, [sectionData?.length]);

  const handleChange = (val, id, key) => {
    const newData = data?.map((item) => {
      return item.id === id ? { ...item, [key]: val } : { ...item };
    });
    setData(() => newData);
  };

  const handleAdd = () =>
    setData(() => [
      ...data,
      {
        id: uuidv4(),
        title: "",
        description: "",
      },
    ]);

  const handleRemove = (id) => {
    let newData = data.filter((item) => item.id !== id);
    setData(newData);
  };

  const handleSubmitHandler = async (e) => {
    e.preventDefault();

    let isValid = true;
    let dataClone = JSON.parse(JSON.stringify(data));

    dataClone?.forEach((item) => {
      isValid &&
        (item?.description ? (isValid = true) : (isValid = false)) &&
        (item?.title ? (isValid = true) : (isValid = false));
    });

    if (isValid) {
      dataClone.forEach((item) => {
        delete item.id;
      });
      const dataForDispatch = stored;
      dataForDispatch[sectionName] = dataClone;
      dispatch(tableDataHandler(dataForDispatch));
      toaster("success", "Data saved successfully");

      const res = dataClone.map((item) => {
        return {
          ...item,
          description: {
            text: item.description || "",
          },
        };
      });
      const updated = await API.updateUiSectionById(sectionId, {
        details: res,
      });

      updated?.data?.success && toaster("success", "Submitted successfully");
      window.location.reload();
    } else {
      toaster("error", "Please fill all the required details");
    }
  };

  return (
    <div className='imageSection__container'>
      <form
        className='mt-2'
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmitHandler(e);
        }}
      >
        {Boolean(data.length)
          ? data.map((item, index) => {
              return (
                <Row className='mt-2' key={item?.id}>
                  <Col sm='12' md='11'>
                    <FormGroup className='w-100'>
                      <Label className='form-label' for='description'>
                        Title
                      </Label>

                      <Input
                        type='text'
                        value={item?.title || ""}
                        id='title'
                        name='title'
                        placeholder='title'
                        onChange={(e) => {
                          handleChange(e.target.value, item?.id, "title");
                        }}
                      />
                    </FormGroup>
                    <FormGroup className='w-100'>
                      <Label className='form-label' for='description'>
                        Description
                      </Label>
                      <ReactQuill
                        modules={modules}
                        theme='snow'
                        defaultValue={item?.description}
                        onChange={(e) => {
                          handleChange(e, item?.id, "description");
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm='12' md='1'>
                    {data.length > 1 ? (
                      <>
                        <Button.Ripple
                          className='btn-icon rounded-circle mb-1 mr-1'
                          color='danger'
                          type='button'
                          size='sm'
                          onClick={() => handleRemove(item?.id)}
                        >
                          <Trash size={14} />
                        </Button.Ripple>
                      </>
                    ) : null}
                  </Col>
                </Row>
              );
            })
          : "Press +Add button for adding new content "}
        <SaveDiscardBtnGroup addAction={handleAdd} />
      </form>
    </div>
  );
};

export default UiSection;

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image"],
    ["clean"],
  ],
};
